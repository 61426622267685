<template>
  <div class="">
    <!-- Content Header (Page header) -->
    <div class="">
      <!-- <button class="btn btn-primary" @click="showText= !showText">Click Me</button> -->
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <custom-alert id="alert_box" v-if="displayAlert == true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card ">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-4 ">Free Agent Player List</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive mt-3">
                    <table class="table table-sm table-bordered table-hover" id="list_table">
                      <thead class="thead-dark">
                      <tr>
                        <th>Member ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Name</th>
                        <th>E-Mail</th>
                        <th>DOB</th>
                        <th>Gender</th>
                        <th>Membership</th>
                        <th>Status</th>
                        <th>Date Paid</th>
                        <th>Start Date</th>
                        <th>Expiration On</th>
                        <th>Waiver Signed On</th>
                        <th>Waiver Expiration On</th>
                        <th>Transaction ID</th>
                        <th>Region</th>
                        <th>Actions</th>
                        <th>Created At</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- Data will be placed here -->
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div><!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->
    <Footer></Footer>
    <b-modal id="playerTransfer" ref="playerTransfer" title="Player Transfer"
             @show="resetPlayerTransfer"
             @hidden="resetPlayerTransfer"
             @ok="handlePlayerTransfer"
    >
      <form ref="form" id="player-transfer-form">
        <div class="form-group">
          <label for="league" class="form-label">Leagues <sup class="text-danger">*</sup></label>
          <select class="form-control" v-model="playerTransferForm.league" id="league" @change="getTeam()" >
            <option value="">Select</option>
            <option  v-for="(leagueData,key) in teamLeague" :key="key" :value="leagueData.id">{{ leagueData.name }}</option>
          </select>
          <span class="text-sm text-danger league-error"></span>
        </div>

        <div class="form-group">
          <label for="age_division" class="form-label">Division <sup class="text-danger">*</sup></label>
          <select class="form-control" @change="getTeam()" id="age_division" v-model="playerTransferForm.age_division"  name="playerTransferForm.age_division" >
            <option value="">Select</option>
            <option  v-for="(listOfAgeDivision,key) in ageDivisionOPt" :key="key"  :name="listOfAgeDivision.text" :value="listOfAgeDivision.id">{{ listOfAgeDivision.text }}</option>
          </select>
          <span class="text-sm text-danger division-error"></span>
        </div>

        <div class="form-group">
          <label for="leagueTeam" class="form-label">Team <sup class="text-danger">*</sup></label>
          <select class="form-control" id="leagueTeam" v-model="playerTransferForm.team" >
            <option value="">Select</option>
            <option value="wait">Wait List</option>
            <option value="free">Free Agents</option>
            <option  v-for="(TeamData,key) in leagueTeam" :key="key" :value="TeamData.id">{{ TeamData.text }}</option>
          </select>
          <span class="text-sm text-danger team-error"></span>
        </div>
        <div class="form-check">
          <input class="form-check-input" v-model="playerTransferForm.notify" type="checkbox" value="" id="defaultCheck1">
          <label class="form-check-label" for="defaultCheck1">
            Notify
          </label>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>

// import RegistrationsListTable from './RegistrationsListTable';
import $ from 'jquery';
// import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import CustomAlert from "../CustomAlert";
// import 'datatables.net-responsive-dt';
import moment from 'moment';
import axios from "axios";


export default {
  name: 'registrations',
  components: {CustomAlert},
  data() {
    return{
      table:'',
      teamLeague: {},
      leagueTeam: [],
      playerTransferForm:{
        league:'',
        team:'',
        notify:'',
        member_id:'',
        age_division:'',
      },
      alertMessage: "",
      displayAlert: "",
      displayMissingMemFields: false,
      ageDivisionOPt:[
        {"id":"4","text":"12U"},
        {"id":"5","text":"10U"}
      ],
      disciplines_list:[],
      loaded:true,
      isTransferEnable:0,
      divisions_lists:[],
      divisions_list:[]
    }
  },
  methods: {
    formatDate(value) {
      var date=  moment(value, "YYYY-MM-DD hh:mm:ss");
      if(date.isValid()){
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    deleteRegistration(id){
      if(confirm("Are you sure you want to delete?")){
        fetch(process.env.VUE_APP_URL + "api/registrations/delete/"+id,{
          method:'delete',
          headers: this.adminHeaders,
        })
            .then(res => res.json())
            .then(data => {
              this.alertMessage = "Record deleted successfully";
              this.displayAlert = true;
              this.table.draw();
              //  dt.row($(this).parents('tr')).remove().draw(true);
            })
            .catch(err => console.log(err));
      }
    },
    getDiscipline()
    {
      axios.get(this.basePath+"api/getDisciplines")
          .then(response => {
            this.disciplines = response.data.data;
            let discipline_codes = Object.keys(this.disciplines);
            if(discipline_codes.length && this.disciplines_list.length == 0){
              discipline_codes.forEach(code => {
                this.disciplines_list.push({ id: code, text: this.disciplines[code] == "NA - N/A" ? "N/A" : this.disciplines[code] });
              });
            }
            this.ageDivisionOPt = this.disciplines_list;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    transferRoster(id){
      this.$refs['playerTransfer'].show()
      this.playerTransferForm.member_id = id;
    },
    validatePlayerTransfer(data){
      let validate = true;
      $('.league-error, .division-error, .team-error').html("");
        if(data.league == ""){
          $('#player-transfer-form .league-error').html("Please select the league");
          validate = false;
        }
        if(data.age_division == ""){
          $('#player-transfer-form .division-error').html("Please select the age division");
          validate = false;
        }
        if(data.team == ""){
          $('#player-transfer-form .team-error').html("Please select the team");
          validate = false;
        }
        return validate;
    },
    handlePlayerTransfer(evt)
    {
      evt.preventDefault();
      if(this.validatePlayerTransfer(this.playerTransferForm) == false) {
        return false;
      }
      axios.post(this.basePath + 'api/club/transferPlayer', this.playerTransferForm, {headers: this.adminHeaders})
          .then(function (response) {
            this.alertMessage = response.data.message;
            this.displayAlert = true;
            this.showLoader = false;
            this.table.draw();
            this.$refs['playerTransfer'].hide()
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    setDangerBg(){
        setTimeout(() => {
          $('#alert_box').children('div').removeClass('alert-success');
          $('#alert_box').children('div').addClass('alert-danger');
        }, 100);
        return false;
    },
    removeDangerBg(){
        setTimeout(() => {
          $('#alert_box').children('div').removeClass('alert-danger');
          $('#alert_box').children('div').addClass('alert-success');
        }, 100);
    },
    resetAlert(){
        this.displayAlert = false;
        this.removeDangerBg();
    },
    resetPlayerTransfer()
    {
      this.playerTransferForm.league = '';
      this.playerTransferForm.team = '';
      this.playerTransferForm.notify = '';
      this.playerTransferForm.member_id = '';
      this.playerTransferForm.age_division='';

    },
    getLeagues()
    {
      axios.get(this.basePath+"api/active_league")
          .then(response => {
            this.teamLeague = response.data.data;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    getTeam()
    {
      let leagueId = this.playerTransferForm.league;
      let teams = this.teamLeague[leagueId].team;
      this.leagueTeam = [];
      teams.forEach(code => {
        if(this.playerTransferForm.age_division == code.age_division){
          this.leagueTeam.push({ id: code.id, text: code.name });
        }
      });
    },
    checkTransferEnable(){
      axios.post(this.basePath + "api/get/playerTransfer/enable", this.updInsurance, {headers: this.adminHeaders})
          .then(
              function (response) {
                const resp = response.data.data;
                this.isTransferEnable = resp.flag;
              }.bind(this)
          ).catch(function (error) {
        console.log(error);
      });
    },
    isUnderAgeDivision(age){
      let membership_id = null;
      console.log("age",age);
      this.divisions_list = [];
      if(age > 12){
        membership_id = 3;
      } else {
        membership_id = null;
      }
      this.getDivisions(membership_id, age);
    },
    getDivisions(id, age)
    {
      let url = this.basePath+"api/getDivisions";
      if(id != null) {
        url =this.basePath+"api/getDivisions/"+id;
      }
      axios.get(url)
          .then(response => {
            this.divisions_lists = response.data.data;
            let discipline_codes = Object.keys(this.divisions_lists);
            if(discipline_codes.length && this.divisions_list.length == 0){
              discipline_codes.forEach(code => {
                let listAge = this.divisions_lists[code].slice(0, -1);
                if (listAge >= parseInt(age)) {
                  this.divisions_list.push({ id: code, text: this.divisions_lists[code] == "NA - N/A" ? "N/A" : this.divisions_lists[code] });
                }
              });
            }
            this.ageDivisionOPt = this.divisions_list;
            this.showLoader=false;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
  },
  mounted(){
    // $.ajaxSetup({
    //     headers: {
    //         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    //         'ClientId': this.sassClientIds[this.subdomain]
    //     }
    // });
   // this.getDiscipline();
    this.checkTransferEnable();
    this.getLeagues();
    this.getCurrentSeason();
    window.reg=this;
    this.table = $("#list_table").DataTable({
      dom: 'lfrtip',
      "bFilter": true,
      processing: true,
      serverSide: true,
      pageLength: 10,
      rowReorder: {
        selector: 'td:nth-child(2)'
      },
      responsive: true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/dashboard/registrations/list/freeAgent",
        type: 'post'
      },
      columns: [
        { data: 'confirmation_code', name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
        { data: "first_name", name: "personal_details.first_name", visible: false, render: (data)=>{return data || "N/A";} },
        { data: "last_name", name: "personal_details.last_name", visible: false, render: (data)=>{return data || "N/A";} },
        { data: "full_name", name: "personal_details.first_name", render: (data)=>{return data || "N/A";} },
        { data: "email", name: "contact_details.email", render: (data)=>{return '<a class="email-link" href="mailto:'+ data +'">'+ data +'</a>' || "N/A";}},
        { data: "dob", name: "personal_details.dob", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
        { data: "gender", name: "personal_details.gender", render: (data)=>{return data || "N/A";} },
        { data: "membership_type", name: "membership.name", render: (data)=>{return data || "N/A";} },
        { data: "reg_status", name: "reg_status", orderable: false, searchable: false, render: (data)=>{return data || "N/A";} },
        { data: "transaction_date", name: "transaction_date", render: (data)=>{return this.formatDate(data)} },
        { data: "r_start_date", name: "registration.start_date", render: (data)=>{return this.formatDate(data)} },
        { data: "expired_at", name: "registration.valid_thru", render: (data)=>{return this.formatDate(data)} },
        { data: "waiver_signed_at", name: "registration.valid_thru", render: (data)=>{return data || "N/A";} },
        { data: "waiver_expired_at", name: "registration.valid_thru", render: (data)=>{return data || "N/A";} },
        { data: "transaction_id", name: "registration.transaction_id", render: (data)=>{return data || "N/A";} },
        { data: "region_code", name: "region.code", render: (data)=>{return data || "N/A";}, visible: this.subdomain != "shooting", searchable: false },
        { data: "actions", name: "actions", orderable: false, searchable: false,
          // render: () => {
          //   return ``
          // } ,
          render: (data,type,row,meta) => {
            let btn = '';
            if(this.getSiteProps('general.scoring')){
              btn += `<a class="primary-text" href="/admin/member/${row.confirmation_code}/score/${row.member_id}" title="Shooting Score"><i class="fas fa-trophy"></i></a> `
            }
            btn += `<a  href="/admin/registrations/${row.member_id}"><i class="fas fa-eye"></i></a> <a class="action-delete" data-id="${row.id}" href="javascript:void(0);"><i class="fas fa-trash"></i></a>`
            if(this.isTransferEnable == 1) {
              btn += `<a class="action-transfer" data-id="${row.member_id}" data-age="${row.personal_details.dob}" href="javascript:void(0);"><i class="fas fa-share text-danger"></i></a>`
            }
            return btn
          }
        },
        { data: "created_at", name: "created_at", render: (data)=>{return data || "N/A";}, visible: false, searchable: false },
      ],
      columnDefs: [
        { targets: 9, orderable: false, searchable: false },
        { targets: 14, visible: this.subdomain == "waterski" },
      ],
      order: [[17, "desc"]]
    });
    var vueRouter = this.$router;
    document.addEventListener("click", function(e){
      if(e.target && e.target.parentNode.className == "actions-click"){
        var id = e.target.parentNode.getAttribute('data-id');
        vueRouter.push('/admin/registrations/'+ id);
      }
      if(e.target && e.target.parentNode.className == "action-delete"){
        var deleteId = e.target.parentNode.getAttribute('data-id');
        window.reg.deleteRegistration(deleteId);
      }
      if(e.target && e.target.parentNode.className == "action-transfer"){
        var transferId = e.target.parentNode.getAttribute('data-id');
        var dob = e.target.parentNode.getAttribute('data-age');
        var age = window.reg.getAgeSeasonWise(dob);
        if(age >= 19) {
          window.reg.alertMessage = "There is no team available for this designated age";
          window.reg.displayAlert = true;
          window.reg.setDangerBg();
          return false;
        }
        window.reg.isUnderAgeDivision(age);
        window.reg.transferRoster(transferId);
      }
    });
  },

  computed: {

  }
}
</script>
<style>
.action-transfer{
  padding-left: 7px !important;
}
</style>